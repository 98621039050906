import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { StatusCardComponent } from './status-card/status-card.component';
import { ContactsComponent } from './component/contacts/contacts.component';
import {
  NbActionsModule,
  NbAutocompleteModule,
  NbButtonModule,
  NbCardModule,
  NbChatModule,
  NbFormFieldModule,
  NbIconModule,
  NbInputModule,
  NbListModule,
  NbPopoverModule,
  NbProgressBarModule,
  NbRadioModule,
  NbSelectModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbTabsetModule,
  NbUserModule,
} from '@nebular/theme';
import { CustomerPopoverComponent } from './component/customers/popover/customer-popover.component';
import { RouterModule } from '@angular/router';
import { CustomerInputComponent } from './component/customers/input/customer-input.component';
import { WorkOrderListComponent } from './component/workorders/list/workorderlist.component';
import { CustomerListComponent } from './component/customers/list/customerlistcomponent';
import { AddressComponent } from './component/common/address//address.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { WorkOrderSegmentListComponent } from './component/workordersegments/list/workordersegmentlist.component';
import { WorkerInputComponent } from './component/worker/input/workerinput.component';
import { LocationListComponent } from './component/locations/list/locationlist.component';
import { LocationInputComponent } from './component/locations/input/locationinput.component';
import { BrySchedulerComponent } from './component/scheduler/scheduler.component';
import { LocationInputSelectComponent } from './component/locations/select/locationinputselect.component';
import { DraggableCellRenderer } from './component/cellrenderers/CellRenderShowInScheduler.component';
import { ChatComponent } from './component/chat/chat.component';
import 'ag-grid-enterprise';
import { AgGridModule } from 'ag-grid-angular';
import { TimeManagementListComponent } from './component/timemanagement/list/timemanagment.component';
import { IndicatorDotComponent } from './component/indicator-dot/indicator-dot.component';


@NgModule({
  declarations: [
    StatusCardComponent,
    ContactsComponent,
    CustomerPopoverComponent,
    WorkOrderListComponent,
    CustomerListComponent,
    AddressComponent,
    WorkOrderSegmentListComponent,
    WorkerInputComponent,
    LocationListComponent,
    LocationInputComponent,
    BrySchedulerComponent,
    LocationInputSelectComponent,
    ChatComponent,
    TimeManagementListComponent,
    IndicatorDotComponent
  ],
  exports: [
    StatusCardComponent,
    ContactsComponent,
    WorkOrderListComponent,
    CustomerListComponent,
    AddressComponent,
    WorkOrderSegmentListComponent,
    WorkerInputComponent,
    LocationListComponent,
    LocationInputComponent,
    BrySchedulerComponent,
    LocationInputSelectComponent,
    TimeManagementListComponent,
    IndicatorDotComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    NbCardModule,
    NbUserModule,
    NbButtonModule,
    NbTabsetModule,
    NbActionsModule,
    NbRadioModule,
    NbSelectModule,
    NbListModule,
    NbButtonModule,
    NbAutocompleteModule,
    NbIconModule,
    NbButtonModule,
    NbSpinnerModule,
    NbPopoverModule,
    NbFormFieldModule,
    NbChatModule,
    NbInputModule,
    AgGridModule,
    NbSidebarModule,
    NbProgressBarModule,
  ],
})
export class SharedModule {}
