import { gql } from 'apollo-angular';

export const QUERY_GETCUSTOMER = gql`
  query ($id: String!) {
    getCustomer(id: $id) {
      customerNum
      id
      name
      type
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      externalId
      billTo {
        customerNum
        name
        id
      }
    }
  }
`;
