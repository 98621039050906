export const SERVICE_VISIT_STATUSES = [
  'Planned',
  'Scheduled',
  'Traveling',
  'On-Site',
  'Paused',
  'Complete',
  'Canceled',
];

export const SERVICE_VISIT_CATEGORIES = [
  'Field',
  'Shop',
  'PM',
  'Transportation',
];

export const buttonConfig = {
  'Planned': {
    'dispatch': '',
    'delete': '',
    'complete': 'Cannot complete a planned service visit',
    'cancel': 'Cannot cancel a planned service visit',
  },
  'Scheduled': {
    'dispatch': '',
    'delete': 'Cannot delete a dispatched service visit',
    'complete': '',
    'cancel': '',
  },
  'Review': {
    'dispatch': '',
    'delete': 'Cannot delete a dispatched service visit',
    'complete': '',
    'cancel': '',
  },
  'Complete': {
    'dispatch': 'Cannot dispatch a completed service visit',
    'delete': 'Cannot delete a completed service visit',
    'complete': 'Cannot complete a completed service visit',
    'cancel': 'Cannot cancel a completed service visit',
  },
  'Canceled': {
    'dispatch': 'Cannot dispatch a canceled service visit',
    'delete': 'Cannot delete a canceled service visit',
    'complete': 'Cannot complete a canceled service visit',
    'cancel': 'Cannot cancel a canceled service visit',
  },
};