import { gql } from 'apollo-angular';

export const QUERY_GETSCHEDULEDSEGMENTSBYIDS = gql`
  query ($data: DtoGetScheduledWorkOrderSegments!) {
    getScheduledWorkOrdersByWorkerIds(data: $data) {
      id
      type
      status
      workOrderNum
      serial
      make
      model
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      subject
      scheduledStartDateTime
      scheduledEndDateTime
      isPastDue
      isMissed
      isClosed
      rescheduleCount
      pastDueCount
      missedCount
      mapURL
      estTravelTime
      estTravelDistance
      estTravelHash
      estTravelFromLatLng
      worker {
        id
        displayName
        user {
          avatarURL
        }
      }
      shipTo {
        id
        name
        customerNum
      }
      billTo {
        id
        name
        customerNum
      }
      equipment {
        id
        equipmentNum
        make
        model
        lastMeterReading
        lastMeterReadingDate
      }
    }
  }
`;
