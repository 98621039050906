import { gql } from 'apollo-angular';

export const Query_GetObjectColumnLayout = gql`
  query ($dto: DtoGetObjectColumnLayout!) {
    getObjectColumnLayoutByObjectType(dto: $dto) {
      id
      page
      userRole
      columns
      buttons
    }
  }
`;
