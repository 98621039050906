import { gql } from 'apollo-angular';

export const QUERY_GetEquipmentInventory = gql`
  query ($search: DtoPaginatedFilter!) {
    getEquipmentInventory(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          equipmentNum
          serial
          make
          model
          group
          category
          modelRef {
            group
            category
          }
          locationNum
          customerEquipmentNum
          lastMeterReadingDate
          lastMeterReading
          status
          orderStatus
          postingStatus
          orderDate
          estimatedReceiptDate
          factoryShipDate
          actualReceiptDate
          fleetType
          description
          actualReceiptDate
          deliveryDate
          status
          var1
          var2
          var3
          var4
          var5
          var6
          var7
          var8
          var9
          var10
          equipmentOptions {
            equipmentModelOption
            description
            group
            qty
            salePrice
            costPrice
            vendorItemNum
          }
          customer {
            id
            name
            customerNum
            address1
            address2
            city
            state
            zipcode
            country
            lat
            lng
            billTo {
              id
              name
              customerNum
            }
          }
        }
      }
    }
  }
`;
