import { gql } from 'apollo-angular';

export const QUERY_GetAttachments = gql`
  query ($data: DtoGetAttachments!) {
    getAttachments(data: $data) {
      id
      description
      fileName
      refId
      object
      type
      signedURL
      createdAt
      isTruckFile
      label
      createdBy {
        id
        fullName
      }
    }
  }
`;
