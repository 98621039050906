import { gql } from 'apollo-angular';

export const Query_GetExternalWorkOrder = gql`
  query ($id: String!) {
    getWorkOrderExternal(id: $id) {
      id
      type
      status
      workOrderNum
      serial
      make
      model
      address
      address1
      address2
      city
      state
      zipcode
      country
      lat
      lng
      subject
      scheduledStartDateTime
      scheduledEndDateTime
      worker {
        id
        displayName
        user {
          avatarURL
        }
      }
      shipTo {
        id
        name
        customerNum
      }
      billTo {
        id
        name
        customerNum
        address
      }
      equipment {
        id
        equipmentNum
        make
        model
        lastMeterReading
        lastMeterReadingDate
        serial
        customerEquipmentNum
      }
    }
  }
`;
