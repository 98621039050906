import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { DtoAddQueue } from '../dto/dtoAddQueue.dto';
import { Mutation_AddQueue } from '../../../../@core/graphql/mutations/queues/addQueue.mutation';
import { Query_GetQueuesByObject } from '../../../../@core/graphql/queries/queues/getQueuesByObject.querie';
import { DtoGetQueuesByObject } from '../dto/DtoGetQueuesByObject';
import { Mutation_DeleteQueue } from '../../../../@core/graphql/mutations/queues/deleteQueue.mutation';

@Injectable({
  providedIn: 'root',
})
export class QueueApiService {
  constructor(private apollo: Apollo) {}

  addQueue(dto: DtoAddQueue) {
    return this.apollo.mutate({
      mutation: Mutation_AddQueue,
      variables: { dto },
    });
  }

  getQueuesByObjectType(dto: DtoGetQueuesByObject) {
    return this.apollo.watchQuery({
      query: Query_GetQueuesByObject,
      variables: { dto },
    });
  }

  deleteQueue(id: string) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteQueue,
      variables: { id },
    });
  }
}
