import { Inject, Injectable } from '@angular/core';
import { Apollo, ApolloModule, gql } from 'apollo-angular';
import { MUTATION_ADDWORKORDER } from '../graphql/mutations/workorders/addworkorder.mutation';
import { MUTATION_ADDWORKORDERSEGMENT } from '../graphql/mutations/workorders/addworkordersegment.mutation';
import { MUTATION_UPDATEWORKORDER } from '../graphql/mutations/workorders/updateworkorder.mutation';
import { MUTATION_UPDATEWORKORDERSEGMENT } from '../graphql/mutations/workorders/updateworkordersegment.mutation';
import { QUERY_GETWORKORDER } from '../graphql/queries/workorders/getworkorder.query';
import { QUERY_GETWORKORDERS } from '../graphql/queries/workorders/getWorkOrders.query';
import { QUERY_GETWORKORDERSEGMENT } from '../graphql/queries/workordersegments/getWorkOrderSegment.query';
import { QUERY_GETWORKORDERSEGMENTBYNUM } from '../graphql/queries/workordersegments/getWorkOrderSegmentByNum';
import { QUERY_GETWORKORDERSEGMENTS } from '../graphql/queries/workordersegments/getWorkOrderSegments';
import { ISearchFilter } from './gridUtil.service';
import { WatchQueryFetchPolicy } from '@apollo/client/core';
import { Query_GetWorkOrderByNum } from '../graphql/queries/workorders/getworkorderbynum.query';
import { Mutation_ScheduleWorkOrder } from '../graphql/mutations/workorders/scheduleworkorder.mutation';
import { QUERY_GETWORKORDERSEGMENTSBYWORKORDERID } from '../graphql/queries/workordersegments/getWorkOrderSegmentsByWorkOrderId';
import { QUERY_GETSCHEDULEDSEGMENTSBYIDS } from '../graphql/queries/workordersegments/getScheduledSegmentsByWorkerIds';
import { Query_GetExternalWorkOrder } from '../graphql/queries/workorders/getexternalworkorder.query';
import { Query_GetWorkOrderChangeLogs } from '../graphql/queries/workorders/getWorkOrderChangeLogs.query';
import { IPaginatedFilter } from '../interfaces/IPaginatedFilter';
import { BehaviorSubject, tap } from 'rxjs';
import { Subscription_WorkOrderUpdates } from '../graphql/subscriptions/workOrderUpdates.subscription';
import { Mutation_AddWorkOrderFromExternalLink } from '../graphql/mutations/workorders/addWorkOrderFromExternalLink.mutation';
import { Mutation_UpdateWorkOrderEstTravel } from '../graphql/mutations/workorders/updateWorkOrderEstTravel.mutation';
import { DtoAddServiceVisit } from '../interfaces/DtoAddServiceVisit';
import { Mutation_AddServiceVisit } from '../graphql/mutations/workorders/addServiceVisit.mutation';
import { Query_GetScheduledServiceVisits } from '../graphql/queries/service-visit/getServiceVisitByWorkerIds';
import { DtoUpdateServiceVisit } from '../interfaces/DtoUpdateServiceVisit';
import { Mutation_UpdateServiceVisit } from '../graphql/mutations/workorders/updateServiceVisit.mutation';
import { Mutation_UpdateServiceVisitEstTravel } from '../graphql/mutations/workorders/updateServiceVisitEstTravel.mutation';
import { Query_GetServiceVisitById } from '../graphql/queries/service-visit/getServiceVisitById';
import { Mutation_AddWorkOrderToServiceVisit } from '../graphql/mutations/workorders/addWorkOrderToServiceVisit.mutation';
import { Mutation_DispatchServiceVisits } from '../graphql/mutations/workorders/dispatchServiceVisits.mutation';
import { Mutation_DeleteWorkOrdersFromServiceVisit } from '../graphql/mutations/workorders/deleteWorkOrdersFromServiceVisit.mutation';
import { Mutation_UpdateServiceVisitAssignment } from '../graphql/mutations/servicevisit/updateServiceVisitAssignment.mutation';
import { Mutation_AddReportingCode } from '../graphql/mutations/workorders/addReportingCode.mutation';
import { Query_GetReportingCodes } from '../graphql/queries/workordersegments/getReportingCodes.query';
import { Mutation_CreateSegmentNote } from '../graphql/mutations/workorders/createSegmentNote.mutation';
import { Mutation_UpdateSegmentNote } from '../graphql/mutations/workorders/updateSegmentNote.mutation';
import { Mutation_DeleteSegmentNote } from '../graphql/mutations/workorders/deleteSegmentNote.mutation';
import { Mutation_UpdateSegmentReportingCode } from '../graphql/mutations/workorders/updateSegmentReportingCode.mutation';
import { Query_GetWorkOrderWorkers } from '../graphql/queries/workorders/getWorkOrderWorkers.query';
import { Mutation_AddWorkersToWorkOrder } from '../graphql/mutations/workorders/addWorkersToWorkOrder.mutation';
import { Mutation_DeleteWorkersFromWorkOrder } from '../graphql/mutations/workorders/deleteWorkersFromWorkOrder.mutation';
import { Query_GetWorkOrderStatuses } from '../graphql/queries/workorders/getWorkOrderStatuses.query';
import { Mutation_UpdateWorkOrderStatus } from '../graphql/mutations/workorders/updateWorkOrderStatus.mutation';
import { Mutation_UpdateWorkOrderPO } from '../graphql/mutations/workorders/updateWorkOrderPO.mutation';
import { Mutation_CreateRecurringServiceVisit } from '../graphql/mutations/workorders/createRecurringServiceVisit';
import { Query_GetServiceVisitsByGroupId } from '../graphql/queries/service-visit/getServiceVisitsByGroupId.query';
import { Mutation_DeleteServiceVisits } from '../graphql/mutations/servicevisit/deleteServiceVisits.mutation';
import {Query_GetWorkOrderFieldGroupByCount } from '../graphql/queries/workorders/getWorkOrderFieldGroupByCount.query';


@Injectable({
  providedIn: 'root',
})
export class WorkOrderService {
  constructor(private apollo: Apollo) {}

  private selectedWorkOrderObservable: BehaviorSubject<any> =
    new BehaviorSubject([]);
  private gridRecordsObservable: BehaviorSubject<any> = new BehaviorSubject([]);
  private schedulerRecordsObservable: BehaviorSubject<any> =
    new BehaviorSubject([]);

  setSelectedWorkOrderObservable(records) {
    this.selectedWorkOrderObservable.next(records);
  }

  async refetchAllWatchQueries() {
    await this.apollo.client.reFetchObservableQueries();
  }

  getSelectedWorkOrderObservable() {
    return this.selectedWorkOrderObservable.asObservable();
  }

  setGridRecordsObservable(records) {
    this.gridRecordsObservable.next(records);
  }

  getGridRecordsObservable() {
    return this.gridRecordsObservable.asObservable();
  }

  setSchedulerRecordsObservable(records) {
    this.schedulerRecordsObservable.next(records);
  }

  getSchedulerRecordsObservable() {
    return this.schedulerRecordsObservable.asObservable();
  }

  createRecurringServiceVisit(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_CreateRecurringServiceVisit,
      variables: { dto },
    });
  }

  deleteServiceVisits(ids: string[]) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteServiceVisits,
      variables: { ids },
    });
  }

  subscribeToWorkOrderUpdates(teams: string[], location: string) {
    return this.apollo.subscribe({
      query: Subscription_WorkOrderUpdates,
      variables: { teams, location },
    });
  }

  addWorkOrder(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_ADDWORKORDER,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERS,
        },
      ],
    });
  }

  updateWorkOrderCache(workOrder: any, inputDto) {
    const id = this.apollo.client.cache.identify({
      ...workOrder,
      __typename: 'WorkOrder',
    });
    const { status, workerId, ...newWorkOrder } = workOrder;
    newWorkOrder.worker = { id: workerId };
    this.apollo.client.cache.modify({
      id: this.apollo.client.cache.identify({
        ...workOrder,
        __typename: 'WorkOrder',
      }),
      fields: {
        worker() {
          return {
            id: workerId,
          };
        },
        scheduledEndDateTime() {
          return newWorkOrder.scheduledEndDateTime;
        },
        scheduledStartDateTime() {
          return newWorkOrder.scheduledStartDateTime;
        },
      },
    });
  }

  getExternalWorkOrder(id: string) {
    return this.apollo.watchQuery({
      query: Query_GetExternalWorkOrder,
      variables: { id },
    }).valueChanges;
  }

  scheduleWorkOrder(dto: any, inputDto) {
    return this.apollo.mutate({
      fetchPolicy: 'network-only',
      mutation: Mutation_ScheduleWorkOrder,
      variables: { data: dto },
    });
  }

  getWorkOrders(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDERS,
      fetchPolicy: 'cache-first',
      variables: { search: search },
    });
  }

  getWorkOrderChangeLogs(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetWorkOrderChangeLogs,
      fetchPolicy: 'cache-and-network',
      variables: { search: search },
    }).valueChanges;
  }

  getWorkOrder(id: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDER,
      variables: { id: id },
    }).valueChanges;
  }

  updateWorkOrderEstTravel(dto: any, inputDto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateWorkOrderEstTravel,
      variables: { dto: dto },
      fetchPolicy: 'network-only',
    });
  }

  getWorkOrderByNum(
    id: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  ) {
    return this.apollo.watchQuery({
      query: Query_GetWorkOrderByNum,
      variables: { id: id },
      fetchPolicy,
    }).valueChanges;
  }

  updateWorkOrder(data: any) {
    return this.apollo.mutate({
      mutation: MUTATION_UPDATEWORKORDER,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDER,
          variables: { id: data.id },
        },
      ],
    });
  }

  getServiceVisitsByGroupId(groupId: string) {
    return this.apollo.watchQuery({
      fetchPolicy: 'cache-and-network',
      query: Query_GetServiceVisitsByGroupId,
      variables: { groupId },
    });
  }

  addWorkOrderToServiceVisit(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_AddWorkOrderToServiceVisit,
      variables: { dto },
    });
  }

  getWorkOrderSegmentsByWorkOrderId(id: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDERSEGMENTSBYWORKORDERID,
      variables: { id: id },
    }).valueChanges;
  }

  getWorkOrderSegments(search: ISearchFilter) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDERSEGMENTS,
      fetchPolicy: 'cache-and-network',
      variables: { search: search },
    }).valueChanges;
  }

  getWorkOrderSegmentByNum(
    segmentNum: string,
    fetchPolicy: WatchQueryFetchPolicy = 'cache-and-network',
  ) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDERSEGMENTBYNUM, //QUERY_GETWORKORDERSEGMENTBYNUM
      variables: { segmentNum: segmentNum },
      fetchPolicy,
    }).valueChanges;
  }

  getWorkOrderSegment(id: string) {
    return this.apollo.watchQuery({
      query: QUERY_GETWORKORDERSEGMENT,
      variables: { id: id },
    }).valueChanges;
  }

  addWorkOrderSegment(data: any, workOrderId: string) {
    return this.apollo.mutate({
      mutation: MUTATION_ADDWORKORDERSEGMENT,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENTS,
          variables: { id: data.workOrderId },
        },
      ],
    });
  }

  addWorkOrderFromExternalLink(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_AddWorkOrderFromExternalLink,
      variables: { dto },
    });
  }

  updateWorkOrderSegment(data: any) {
    return this.apollo.mutate({
      fetchPolicy: 'no-cache',
      mutation: MUTATION_UPDATEWORKORDERSEGMENT,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENT,
          variables: { id: data.id },
        },
      ],
    });
  }

  getScheduledSegmentsByWorkerIds(data: any) {
    return this.apollo.query({
      query: QUERY_GETSCHEDULEDSEGMENTSBYIDS,
      variables: { data },
    });
  }

  getServiceVisitById(id: string) {
    return this.apollo.watchQuery({
      fetchPolicy: 'cache-and-network',
      query: Query_GetServiceVisitById,
      variables: { id: id },
    }).valueChanges;
  }

  updateServiceVisitEstTravel(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateServiceVisitEstTravel,
      variables: { dto },
    });
  }

  addServiceVisit(dto: DtoAddServiceVisit) {
    return this.apollo.mutate({
      mutation: Mutation_AddServiceVisit,
      variables: { dto },
    });
  }

  updateServiceVisit(dto: Partial<DtoUpdateServiceVisit>) {
    return this.apollo.mutate({
      fetchPolicy: 'network-only',
      mutation: Mutation_UpdateServiceVisit,
      variables: { dto },
      refetchQueries: [
        {
          query: Query_GetServiceVisitById,
          variables: { id: dto.id },
        },
      ],
    });
  }

  updateServiceVisitAssignment(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateServiceVisitAssignment,
      variables: { dto },
    });
  }
  getScheduledServiceVisitsByWorkerIds(data: any) {
    return this.apollo.watchQuery({
      fetchPolicy: 'network-only',
      pollInterval: 60000,
      query: Query_GetScheduledServiceVisits,
      variables: { data },
    });
  }

  dispatchServiceVisits(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_DispatchServiceVisits,
      variables: { dto },
    });
  }

  deleteWorkOrdersFromServiceVisit(ids: string[]) {
    return this.apollo
      .mutate({
        mutation: Mutation_DeleteWorkOrdersFromServiceVisit,
        variables: { ids },
      })
      .subscribe(
        (res) => {},
        (error) => console.log(error),
      );
  }

  getReportingCodes(search: any) {
    return this.apollo.watchQuery({
      query: Query_GetReportingCodes,
      fetchPolicy: 'cache-first',
      variables: { search },
    }).valueChanges;
  }

  addReportingCode(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_AddReportingCode,
      variables: { dto },
    });
  }

  updateSegmentReportingCode(data: any, search: any, tabKey) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateSegmentReportingCode,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENTS,
          variables: { search },
        },
      ],
    });
  }

  createSegmentNote(data: any, search: any) {
    return this.apollo.mutate({
      mutation: Mutation_CreateSegmentNote,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENTS,
          variables: { search },
        },
      ],
    });
  }

  updateSegmentNote(data: any, search: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateSegmentNote,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENTS,
          variables: { search },
        },
      ],
    });
  }

  deleteSegmentNote(data: any, search: any) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteSegmentNote,
      variables: { data },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDERSEGMENTS,
          variables: { search },
        },
      ],
    });
  }

  getWorkOrderWorkers(search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetWorkOrderWorkers,
      variables: { search: search },
    });
  }

  addWorkersToWorkOrder(workOrderId: string, workerNums: string[]) {
    return this.apollo.mutate({
      mutation: Mutation_AddWorkersToWorkOrder,
      variables: { workOrderId, workerNums },
    });
  }

  removeWorkersFromWorkOrder(workOrderId: string, workerIds: string[]) {
    return this.apollo.mutate({
      mutation: Mutation_DeleteWorkersFromWorkOrder,
      variables: { workOrderId, workerIds },
    });
  }

  getWorkOrderStatuses() {
    return this.apollo.query({
      query: Query_GetWorkOrderStatuses,
      fetchPolicy: 'cache-first',
    });
  }

  updateWorkOrderStatus(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateWorkOrderStatus,
      variables: { dto },
    });
  }

  updateWorkOrderPO(dto: any) {
    return this.apollo.mutate({
      mutation: Mutation_UpdateWorkOrderPO,
      variables: { dto },
      refetchQueries: [
        {
          query: QUERY_GETWORKORDER,
          variables: { id: dto.id },
        },
      ],
    });
  }

  getWorkOrderFieldGroupByCount(fields: string[], search: IPaginatedFilter) {
    return this.apollo.watchQuery({
      query: Query_GetWorkOrderFieldGroupByCount,
      variables: { search: search, fields: fields },
    }).valueChanges;
  }
}
