import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NbToastrService } from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { WorkOrderService } from '../../../@core/services/workorder.service';
import { CustomerPopoverComponent } from '../../../shared/component/customers/popover/customer-popover.component';

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'ngx-workorderexternal',
  templateUrl: './workorderexternal.component.html',
  styleUrls: ['./workorderexternal.component.scss'],
})
export class WorkOrderExternalDetailComponent implements OnInit, OnDestroy {
  workorder: any;
  id: string;
  error: string;
  paramSub$;
  shipTo: any;
  billTo: any;
  equipment: any;
  activeTab: string = 'Details';

  form = new FormGroup({
    id: new FormControl(''),
    serial: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
    shipToId: new FormControl(''),
    billToId: new FormControl(''),
    equipmentId: new FormControl(''),
    externalId: new FormControl(''),
    customerEquipmentNum: new FormControl(''),
    subject: new FormControl(''),
    note_ProblemDesc: new FormControl(''),
    note_Findings: new FormControl(''),
    note_Corrections: new FormControl(''),
    note_Internal: new FormControl(''),
    type: new FormControl(''),
    status: new FormControl(''),
    poNumber: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    scheduledStartDateTime: new FormControl(''),
    scheduledEndDateTime: new FormControl(''),
  });

  constructor(
    private workOrderService: WorkOrderService,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private toaster: NbToastrService
  ) {
    this.setupParamSub();
    this.setupQueryParamSub();
  }

  ngOnDestroy() {
    if (this.paramSub$) this.paramSub$.unsubscribe();
  }

  setupParamSub() {
    this.paramSub$ = this.activatedRoute.params.subscribe((params) => {
      const id = params['id'];
      this.getWorkOrder(id);
    });
  }

  ngOnInit() {}

  setupQueryParamSub() {
    this.activatedRoute.queryParamMap.subscribe((map: any) => {
      this.activeTab = map.params.tab;
    });
  }

  getWorkOrder(id: string) {
    this.workOrderService.getExternalWorkOrder(id).subscribe((_workorder) => {
      const workorder: any = _workorder;
      console.log(workorder);
      this.workorder = workorder.data.getWorkOrderExternal;
      this.shipTo = this.workorder.shipTo;
      this.billTo = this.workorder.billTo;
      this.equipment = this.workorder.equipment;
      this.form.patchValue(this.workorder);
    });
  }

  shipToChange(customer: any) {
    this.shipTo = customer;
    const { address1, address2, city, state, zipcode, country, id } = customer;

    this.form.patchValue({
      address1,
      address2,
      city,
      state,
      zipcode,
      country,
      shipToId: id,
    });

    if (this.shipTo && this.shipTo.billTo) {
      this.billToChange(this.shipTo.billTo);
      return;
    }
    if (this.shipTo.type === 'CASH') {
      this.billToChange(this.shipTo);
      return;
    }
  }

  billToChange(customer: any) {
    this.billTo = customer;
    this.form.controls['billToId'].setValue(customer.id);
  }

  equipmentChange(equipment) {
    this.equipment = equipment;
    if (!equipment) return;
    if (equipment.customer) {
      this.shipToChange(equipment.customer);
    }

    const { make, model, serial, customerEquipmentNum, id } = this.equipment;

    this.form.patchValue({
      make,
      model,
      serial,
      customerEquipmentNum,
      equipmentId: id,
    });
  }

  updateWorkOrder() {
    let obj: any = this.form.value;
    obj.shipToId = this.shipTo?.id;
    obj.billToId = this.billTo?.id;
    obj.equipmentId = this.equipment?.id ?? null;

    this.workOrderService.updateWorkOrder(obj).subscribe(
      (rnt) => {
        this.toaster.primary('Saved!', this.workorder.workOrderNum);
      },
      (err) => {
        this.error = err;
      }
    );
  }

  onTabChange(tab: any) {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { tab: tab.tabTitle },
      // queryParamsHandling: 'merge'
    });
  }
}
