import {
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  Renderer2,
  TemplateRef,
  ViewChild,
  ViewChildren,
  signal,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import {
  NbAccordionComponent,
  NbToastrService,
  NbMenuService,
  NbDialogService,
} from '@nebular/theme';
import { UntilDestroy } from '@ngneat/until-destroy';
import { PubSubService } from '../../../@core/services/componentmessage.service';
import { WorkOrderService } from '../../../@core/services/workorder.service';
import { TimeManagmentService } from '../../timemanagement/services/timemanagment.service';
import { Subject, debounceTime, switchMap } from 'rxjs';
import { SubSink } from 'subsink';
import { CODE_MENU_ITEMS, NOTE_MENU_ITEMS, TABS } from './constants';
import { TokenService } from '../../../@core/services/token.service';
import { IPaginatedFilter } from '../../../@core/interfaces/IPaginatedFilter';
import { ISearchFilterColumnDef } from '../../../@core/interfaces/ISearchFilterColumnDef';
import { WindowService } from '../../../@core/services/window.service';
import { WorkOrderDetailComponent } from '../../workorder/detail/workorderdetail.component';

interface NbMenuContext {
  id: string;
  key: string;
}

@UntilDestroy({ checkProperties: true })
@Component({
  selector: 'next-workordersegmentdetail',
  templateUrl: './workordersegmentdetail.component.html',
  styleUrls: ['./workordersegmentdetail.component.scss'],
})
export class WorkOrderSegmentDetailComponent implements OnInit, OnDestroy {
  private reportingCodesUpdate$ = new Subject<any>();
  private destroy$ = new Subject<void>();
  private subs = new SubSink();
  expandIconPosition: 'right' | 'left' = 'right';
  _workOrder: any;
  segment: any;
  activeSegmentId: string;
  id: string;
  error: string;
  paramSub$;
  billTo: any;
  worker: any;
  equipment: any;
  toggleNgModel = true;
  isOpen = false;
  badgeDetails: any;
  partsClosed = signal(true);
  hoursClosed = signal(true);
  chargesClosed = signal(true);
  notesClosed = signal(true);
  segmentNotes = '';
  avatarUrls: string[] = [];
  isAddingNewNote = false;
  newNote = '';
  options: { name: string; description: string }[] = [];
  filteredOptions;
  tabs = TABS;
  noteMenuItems = NOTE_MENU_ITEMS;
  codeMenuItems = CODE_MENU_ITEMS;
  noteEditIndex = null;
  editedNoteControl = '';
  user: any;
  inputFocused = false;
  selectedOptions = new Set();
  currentTab = TABS[0];
  initialSegmentReportingCodes: any[] = [];
  initialSelectedOptions: any[] = [];
  codeEditMode = true;
  codesChanged = false;
  totalParts = 0;
  totalPartsNotPosted = 0;
  chargeLinesNotPosted = 0;
  selectedOptionIds = new Set<string>();
  jobcodeClosed = signal(true);
  jobcodesQuickInfo = [];
  o;

  @ViewChildren('dropdownContainer') dropdownContainer: ElementRef;
  @ViewChildren('customDropdown') customDropdowns: QueryList<ElementRef>;
  @ViewChildren('autoInput') autoInputs: QueryList<ElementRef>;
  @ViewChild(NbAccordionComponent) accordionComponent: NbAccordionComponent;
  @ViewChild('tab') tab: ElementRef;
  @Input() showList: boolean;
  @Input() orFilters: ISearchFilterColumnDef[];
  @Input() set workOrder(workorder) {
    const oldWorkOrder = this._workOrder;
    this._workOrder = workorder;

    if (!workorder) return;

    if (oldWorkOrder && oldWorkOrder.id !== workorder.id) {
      this.segment = null;
    }
  }

  get workOrder() {
    return this._workOrder;
  }

  form = new FormGroup({
    id: new FormControl(''),
    subject: new FormControl(''),
    status: new FormControl(''),
    billToId: new FormControl(''),
    equipmentId: new FormControl(''),
    make: new FormControl(''),
    model: new FormControl(''),
    serial: new FormControl(''),
    customerEquipmentNum: new FormControl(''),
    externalId: new FormControl(''),
    poNumber: new FormControl(''),
    address1: new FormControl(''),
    address2: new FormControl(''),
    city: new FormControl(''),
    state: new FormControl(''),
    zipcode: new FormControl(''),
    country: new FormControl(''),
    lat: new FormControl(''),
    lng: new FormControl(''),
    workerId: new FormControl(null),
    scheduledStartDateTime: new FormControl(''),
    scheduledEndDateTime: new FormControl(''),
    newNote: new FormControl(''),
    selectedOptions: new FormControl(''),
  });

  constructor(
    private renderer: Renderer2,
    private el: ElementRef,
    private menu: NbMenuService,
    private workOrderService: WorkOrderService,
    private toaster: NbToastrService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private pubSub: PubSubService,
    private tokenService: TokenService,
    private dialogService: NbDialogService,
    private timeManagementService: TimeManagmentService,
    private windowService: WindowService,
  ) {
    this.badgeDetails = {
      unpostedHours: 0,
      totalHours: 0,
    };
    this.subs.sink = this.menu.onItemClick().subscribe((event) => {
      const tag: NbMenuContext = event.tag as unknown as NbMenuContext;
      if (event.item.title === 'Delete') {
        this.deleteNote(tag.id, tag.key);
      } else if (event.item.title === 'Edit Note') {
        this.editNote(tag.id, tag.key);
      } else if (event.item.title === 'Edit Codes') {
        this.handleCodesEdit(tag.key);
      }
    });
    this.setupQueryParamSub();
    this.setupParamSub();
  }

  protected open(dialog: TemplateRef<any>, context: any) {
    this.dialogService.open(dialog, {
      hasBackdrop: false,
      closeOnBackdropClick: false,
      context: context,
    });
  }

  setupParamSub() {
    this.paramSub$ = this.activatedRoute.params.subscribe((params) => {
      const id = params['segment'];
      if (id) this.getWorkOrderSegment(id);
    });
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
    if (this.paramSub$) this.paramSub$.unsubscribe();
    this.destroy$.next();
    this.destroy$.complete();
  }

  ngOnInit() {
    // this.filteredOptions$ = of(this.options);
    this.subs.sink = this.tokenService.get().subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
    });

    this.subs.sink = this.reportingCodesUpdate$
      .pipe(
        debounceTime(2000),
        switchMap((data) =>
          this.workOrderService.updateSegmentReportingCode(
            data.data,
            data.search,
            data.tabKey,
          ),
        ),
      )
      .subscribe({
        next: ({ data }) => {},
        error: (e) => console.error('Error updating reporting codes', e),
      });

    // this.getHours('true', 'postedHours');
    // this.getHours('false', 'unpostedHours');
  }

  getReportingCodesByType(type: string) {
    const search = {
      first: 500,
      filter: {
        column: 'type',
        value: `${type}`,
      },
    };
    this.subs.sink = this.workOrderService
      .getReportingCodes(search)
      .subscribe(({ data }) => {
        const codes = data['getReportingCodes'].edges.map((e) => e.node);
        this.options = codes;
        this.filteredOptions = [...this.options];
      });
  }

  @HostListener('document:click', ['$event'])
  onDocumentClick(event: MouseEvent) {
    const target = event.target as HTMLElement;
    const clickedOutsideDropdown = !this.customDropdowns.some((dropdown) =>
      dropdown.nativeElement.contains(target),
    );
    const clickedOutsideInput = !this.autoInputs.some((input) =>
      input.nativeElement.contains(target),
    );

    if (clickedOutsideDropdown && clickedOutsideInput) {
      this.inputFocused = false;
    }
  }

  onInputFocus(inputElement: HTMLElement) {
    this.inputFocused = true;
  }

  getWorkOrderSegment(id: string) {
    // if (!id) return;
    // console.log('Getting Segment for id ', id);
    // this.workOrderService.getWorkOrderSegment(id).subscribe(({ data }) => {
    //   const segment: any = (<any>data).getWorkOrderSegment;
    //   this.segmentSelected(segment);
    // });
  }

  setupQueryParamSub() {
    this.activatedRoute.queryParamMap.subscribe((map: any) => {
      this.activeSegmentId = map.params.segment;
    });
  }

  updateAvatarUrls(): void {
    const newUrls = [];
    for (let tab of this.tabs) {
      for (let note of this.segment[tab.key].notes) {
        const avatarURL = note.updatedByRef?.avatarURL;
        if (avatarURL && !newUrls.includes(avatarURL)) {
          newUrls.push(avatarURL);
        }
      }
    }
    this.avatarUrls = newUrls;
  }

  updateTabsWithCodeInfo() {
    this.tabs.forEach((tab) => {
      if (!tab.excludeHeader) {
        tab.hasCodes = this.segment[tab.key].reportingCodes.length > 0;
      }
    });
  }

  segmentSelected(segment) {
    if (!segment) return;
    let updateParam = true;
    if (!this.segment) {
      updateParam = false;
    }
    this.segment = segment[0];
    this.updateAvatarUrls();
    this.updateTabsWithCodeInfo();
    if (updateParam) this.updateSegmentParam();
    this.getHours('true', 'postedHours');
    this.getHours('false', 'unpostedHours');
    this.onTabChange({ tabTitle: this.currentTab.title });
  }

  billToChange(customer: any) {
    this.billTo = customer;
    this.form.patchValue({
      billToId: customer.id,
    });
  }

  workerChange(workerId: string) {
    this.form.patchValue({
      workerId,
    });
  }

  equipmentChange(equipment) {
    this.equipment = equipment;
    if (!equipment) return;

    const { make, model, serial, customerEquipmentNum, id } = this.equipment;

    this.form.patchValue({
      make,
      model,
      serial,
      customerEquipmentNum,
      equipmentId: id,
    });
  }

  updateSegmentParam() {
    if (!this.form?.value?.id) return;
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: { segment: this.form?.value?.id },
      queryParamsHandling: 'merge',
    });
  }

  updateWorkOrderSegment() {
    const obj: any = this.form.value;
    this.subs.sink = this.workOrderService
      .updateWorkOrderSegment(obj)
      .subscribe(
        (rtn: any) => {
          this.pubSub.Broadcast('onSegmentUpdate', null);
          this.toaster.primary('Updated!', this.segment.workOrderSegmentNum);
        },
        (err) => {
          this.error = err;
        },
      );
  }

  toggleAll(): void {
    this.isOpen
      ? this.accordionComponent.closeAll()
      : this.accordionComponent.openAll();
    this.isOpen = !this.isOpen;
  }

  generateStatusTag(hours: number) {
    if (hours < 1000) {
      return 'success';
    } else if (hours > 1000 && hours < 2000) {
      return 'warning';
    } else {
      return 'danger';
    }
  }

  onJobcdeCollapseChanged(event) {
    this.jobcodeClosed.set(event);
  }

  onPartsCollapseChanged(event) {
    this.partsClosed.set(event);
  }

  onHoursCollapseChanged(event) {
    this.hoursClosed.set(event);
  }

  onChargesCollapseChanged(event) {
    this.chargesClosed.set(event);
  }

  onNotesCollapseChanged(event) {
    this.notesClosed.set(event);
  }

  handleCodesEdit(tabKey: string) {
    this.codeEditMode = true;
  }

  addNewNote() {
    this.isAddingNewNote = true;
  }

  resetNewNote() {
    this.isAddingNewNote = false;
    this.newNote = '';
  }

  async saveNewNote(type, segmentNoteKey) {
    const noteValue = this.newNote;
    if (!noteValue.trim()) {
      this.isAddingNewNote = false;
      return;
    }
    if (noteValue.trim() !== '') {
      const dto: any = {
        workOrderSegmentId: this.segment.id,
        note: noteValue.trim(),
        type: type,
      };
      const search = {
        filter: {
          column: 'id',
          value: `${this.segment.id}`,
        },
      };
      this.subs.sink = this.workOrderService
        .createSegmentNote(dto, search)
        .subscribe(({ data }) => {
          if (this.segment[segmentNoteKey].notes) {
            this.segment[segmentNoteKey].notes.unshift(
              (<any>data).createSegmentNote,
            );
          } else {
            this.segment[segmentNoteKey].notes.push(
              (<any>data).createSegmentNote,
            );
          }
          this.newNote = '';
        });
      this.resetNewNote();
    }
  }

  editNote(id, noteKey) {
    const index = this.segment[noteKey].notes.findIndex(
      (note) => note.id === id,
    );
    this.noteEditIndex = index;
    this.editedNoteControl = this.segment[noteKey].notes[index].note;
  }

  saveEditNote(type, noteKey) {
    const editedNote = this.editedNoteControl;
    if (!editedNote.trim()) {
      this.noteEditIndex = null;
      return;
    }
    if (editedNote.trim() !== '') {
      const dto: any = {
        id: this.segment[noteKey].notes[this.noteEditIndex].id,
        workOrderSegmentId: this.segment.id,
        note: editedNote.trim(),
        type,
      };
      const search = {
        filter: {
          column: 'id',
          value: `${this.segment.id}`,
        },
      };
      this.subs.sink = this.workOrderService
        .updateSegmentNote(dto, search)
        .subscribe(({ data }) => {
          this.segment[noteKey].notes[this.noteEditIndex] = (<any>(
            data
          )).updateSegmentNote;
          this.noteEditIndex = null;
          this.editedNoteControl = '';
        });
    }
  }

  cancelEditNote() {
    this.noteEditIndex = null;
    this.editedNoteControl = '';
  }

  deleteNote(id, notesKey) {
    const data = {
      id,
      workOrderSegmentId: this.segment.id,
    };

    const search = {
      filter: {
        column: 'id',
        value: `${this.segment.id}`,
      },
    };

    this.subs.sink = this.workOrderService
      .deleteSegmentNote(data, search)
      .subscribe(({ data }) => {
        const index = this.segment[notesKey].notes.findIndex(
          (note) => note.id === id,
        );
        if (index !== -1) {
          this.segment[notesKey].notes.splice(index, 1);
        }
      });
  }

  onChange(searchTerm: string) {
    if (!searchTerm) {
      this.filteredOptions = [...this.options];
    } else {
      this.filteredOptions = this.options.filter(
        (option) =>
          option.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          option.description.toLowerCase().includes(searchTerm.toLowerCase()),
      );
    }
  }

  toggleSelection(option: any): void {
    if (this.selectedOptions.has(option.id)) {
      this.selectedOptions.delete(option.id);
      this.onTagRemove(option);
    } else {
      this.selectedOptions.add(option.id);
      this.onTagAdd(option);
    }
  }

  isSelected(option: any): boolean {
    return this.selectedOptions.has(option.id);
  }

  toggleCheckbox(option) {
    option.isSelected = !option.isSelected;
  }

  trackByFn(index, option) {
    return option.name;
  }

  onTabChange(event) {
    const matchingTab = TABS.find((tab) => tab.title === event.tabTitle);
    this.currentTab = matchingTab;

    if (this.currentTab) {
      this.getReportingCodesByType(this.currentTab.title);
    }
    const prevSelectedCodes =
      this.segment?.[this.currentTab.key].reportingCodes || [];
    this.selectedOptions = new Set(
      prevSelectedCodes.map((code) => code.reportingCode.id),
    );
  }

  saveReportingCodes() {
    const search = {
      filter: {
        column: 'id',
        value: `${this.segment.id}`,
      },
    };
    const data = {
      type: this.currentTab.title,
      workOrderSegmentId: this.segment.id,
      reportingCodeIds: Array.from(this.selectedOptions),
    };

    this.reportingCodesUpdate$.next({
      data,
      search,
      tabKey: this.currentTab.key,
    });
  }

  onTagAdd(code) {
    const reportingCodesArray =
      this.segment[this.currentTab.key].reportingCodes;
    const indexInSegment = reportingCodesArray.findIndex(
      (item) => item.reportingCodeId === code.id,
    );
    if (indexInSegment === -1) {
      reportingCodesArray.push({ id: code.id, reportingCode: code });
    }
    this.ifCodesChanged();
    this.saveReportingCodes();
  }

  onTagRemove(selectedTag: any, ref?: any): void {
    ref?.close();
    this.removeTagFromSelectedOptions(selectedTag);
    this.removeTagFromReportingCodes(selectedTag);
    this.ifCodesChanged();
    this.saveReportingCodes();
  }

  removeTagFromSelectedOptions(selectedTag: any): void {
    const tagId = selectedTag.reportingCode?.id || selectedTag.id;
    if (this.selectedOptions.has(tagId)) {
      this.selectedOptions.delete(tagId);
    }
  }

  removeTagFromReportingCodes(selectedTag: any): void {
    const reportingCodesArray =
      this.segment[this.currentTab.key].reportingCodes;
    const indexInSegment = this.findIndexInSegment(
      reportingCodesArray,
      selectedTag,
    );
    if (indexInSegment !== -1) {
      reportingCodesArray.splice(indexInSegment, 1);
    }
  }

  findIndexInSegment(reportingCodesArray: any[], selectedTag: any): number {
    return reportingCodesArray.findIndex((code) => {
      return (
        code.id === selectedTag.id ||
        (code.reportingCode && code.reportingCode.id === selectedTag.id)
      );
    });
  }

  cancelEditCodes() {
    this.selectedOptions = new Set([...this.initialSelectedOptions]);
    this.segment[this.currentTab.key].reportingCodes = JSON.parse(
      JSON.stringify(this.initialSegmentReportingCodes),
    );
    this.ifCodesChanged();
  }

  ifCodesChanged() {
    const changed = !this.arraysEqual(
      this.selectedOptions,
      this.initialSelectedOptions,
    );
    this.codesChanged = changed;
  }

  private arraysEqual(a, b) {
    if (a.length !== b.length) return false;
    for (let i = 0; i < a.length; i++) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  getHours(postingStatus: string, property: string) {
    if (!this._workOrder?.workOrderNum) return;

    let filter: IPaginatedFilter = {
      first: 1,
      aggregate: 'Sum',
      filter: [
        {
          column: 'isPosted',
          value: postingStatus,
        },
        {
          column: 'workOrderSegment.workOrder.workOrderNum',
          value: this.segment
            ? this.segment.workOrder.workOrderNum
            : this._workOrder?.workOrderNum,
        },
      ],
    };
    this.subs.sink = this.timeManagementService
      .getSumTimeSheets(filter)
      .subscribe(({ data }) => {
        let placeholder: any;
        placeholder = data;
        let hours = placeholder?.getSumTimeSheets;
        if (hours) {
          this.badgeDetails[property] = parseInt(placeholder?.getSumTimeSheets);
        }
      });
  }

  onPartRecords(records) {
    this.totalParts = records.reduce((a, b) => a + b.qty, 0);
    this.totalPartsNotPosted = records.reduce((a, b) => a + b.qtyToUse, 0);
  }

  onJobcodeRecords(records) {
    console.log('jobcodes', records);
    this.jobcodesQuickInfo = records.slice(0, 3).map((jc) => jc?.itemId);
    if (records.length > 3) {
      this.jobcodesQuickInfo.push('+');
    }
  }

  onChargeRecords(records) {
    this.chargeLinesNotPosted = records.reduce((a, b) => a + b.qtyToUse, 0);
  }

  onTimeSheetRecords(records) {
    this.badgeDetails.totalHours = 0;
    this.badgeDetails.unpostedHours = 0;

    if (records?.length > 0) {
      this.badgeDetails.totalHours = records
        ?.reduce((a, b) => a + b.qty, 0)
        .toFixed(4);

      this.badgeDetails.unpostedHours = records?.reduce(
        (a, b) => (b?.isPosted ? 0 : a + b?.qtyToUse),
        0,
      );
    }
  }

  onClick(workOrderSegment) {
    const { id } = workOrderSegment.workOrder;
    if (!id) return;
    this.windowService.openWindow(WorkOrderDetailComponent, {
      objectId: id,
      context: {
        id: id,
      },
    });
  }
}
