import { gql } from 'apollo-angular';

export const Query_GetServiceVisitById = gql`
  query ($id: String!) {
    getServiceVisit(id: $id) {
      id
      serviceVisitNum
      status
      address
      address1
      address2
      city
      state
      category
      country
      lat
      lng
      locationId
      mapURL
      scheduledStartDateTime
      scheduledEndDateTime
      estTravelDistance
      estTravelTime
      estTravelFromLatLng
      estTravelPolyline
      duration
      groupId
      contact {
        firstname
        lastname
        fullName
        email
        phone
        category
        contactNum
      }
      isActive
      statusRef {
        color
      }
      timeSheets {
        id
        isOpen
        lat
        lng
        startDateTime
        endDateTime
        worker {
          id
          displayName
        }
        activityCode {
          id
          code
          fabLabel
          fabIcon
          fabColor
          label
        }
      }
      customer {
        id
        name
        customerNum
      }
      serviceVisitWorkers {
        id
        status
        scheduledStartDateTime
        scheduledEndDateTime
        estTravelDistance
        estTravelTime
        estTravelFromLatLng
        estTravelPolyline
        worker {
          displayName
          workerNum
          id
          user {
            id
            email
            avatarURL
          }
        }
      }
      serviceVisitWorkOrders {
        id
        workOrderStatus
        workOrderStatusRef {
          isCompleteOnServiceVisit
        }
        workOrder {
          id
          equipmentId
          workOrderNum
          subject
          status
          type
        }
      }
    }
  }
`;
