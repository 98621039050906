import { gql } from "apollo-angular";

export const Mutation_DeleteWorkersFromServiceVisit = gql`
  mutation deleteWorkersFromServiceVisit($ids: [String!]!) {
    deleteWorkersFromServiceVisit(ids: $ids) {
      id
      serviceVisitWorkers {
        id
        worker {
          id
        }
      }
    }
  }
`;