<div class="pr-3">
  <img
    alt="Attachment"
    masonryLazy
    loading="lazy"
    [src]="attachment?.signedURL"
    crossorigin="anonymous"
    [id]="attachment?.id"
    [style.max-width.px]="width"
    [style.min-height.px]="100"
    (load)="imageDidLoad()"
    (error)="handleError()"
    *ngIf="
      !isOfficeDocument(attachment?.fileName) &&
      !attachment?.fileName.toLowerCase().endsWith('pdf') &&
      !attachment?.imageError
    "
    (click)="displayImage(attachment)"
  />

  @if (attachment.loadingError) {
    <nb-card
      class="loading-card"
      [nbSpinner]="attachment.loadingError"
      nbSpinnerStatus="primary"
      nbSpinnerMessage="In Progress..."
    ></nb-card>
  } @else if (imageLoadFailed) {
    <nb-card>
      <nb-card-body>
        <nb-icon style="width: 30px; height: 30px" icon="image-outline">
          Image failed to load
        </nb-icon>
      </nb-card-body>
    </nb-card>
  } @else {
    <!-- PDF viewer -->
    <pdf-viewer
      [src]="attachment?.signedURL"
      [original-size]="false"
      [fit-to-page]="true"
      (after-load-complete)="imageDidLoad()"
      [autoresize]="true"
      [style.width.px]="width"
      [style.height.px]="width + 120"
      (click)="displayImage(attachment)"
      (error)="handleError($event, attachment)"
      *ngIf="
        !attachment.imageError &&
        attachment?.fileName.toLowerCase().endsWith('pdf')
      "
    ></pdf-viewer>

    <!-- Office document viewer -->
    <iframe
      [src]="msURL"
      [style.width.px]="width"
      [style.height.px]="width + 120"
      frameborder="0"
      *ngIf="!attachment.imageError && isOfficeDocument(attachment?.fileName)"
      (load)="imageDidLoad()"
      (error)="handleError()"
    ></iframe>
  }

  <span *ngIf="attachment.imageError">
    <span *ngIf="getFileExtension(attachment?.fileName) as ext">
      <span class="defaultFileExt">
        <span *ngIf="ext !== ''" style="padding-left: 4px"> .{{ ext }} </span>
        <span style="padding-left: 10px" *ngIf="ext === ''">
          <nb-icon
            style="width: 30px; height: 30px"
            icon="question-mark-outline"
          ></nb-icon>
        </span>
      </span>
    </span>
  </span>
</div>
