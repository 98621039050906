import { gql } from 'apollo-angular';

export const QUERY_GETCHECKLISTS = gql`
  query ($search: DtoPaginatedFilter!) {
    getAllChecklistsByWorkOrderId(search: $search) {
      totalCount
      pageInfo {
        hasNextPage
      }
      edges {
        node {
          id
          createdAt
          updatedAt
          createdBy
          updatedBy
          checkListTemplateId
          json
          # equipment {
          #   id
          #   category
          #   group
          #   equipmentNum
          #   description
          #   serial
          #   make
          #   model
          # }
        }
      }
    }
  }
`;
