<next-object-list
  *ngIf="showList"
  object="WorkOrderSegment"
  height="250"
  [hideControlBar]="false"
  [hideToolBar]="true"
  [hideQueues]="true"
  [selectFirstRow]="true"
  [orFilters]="orFilters"
  (onSelect)="segmentSelected($event)"
  rowSelection="single"
  (onDoubleClick)="onClick($event)"
></next-object-list>
<br />
<!-- <nb-card>
  <nb-card-body> -->
<div class="p-2">
  <div autocomplete="off" *ngIf="segment">
    <div class="header-container">
      <div>
        <h6>{{ segment?.workOrderSegmentNum }}</h6>
        <p class="text-hint subHeader">{{ form?.value?.subject }}</p>
      </div>
      <span class="flex-expand"></span>
    </div>
    <nb-accordion multi>
      <nb-accordion-item
        (collapsedChange)="onJobcdeCollapseChanged($event)"
        [collapsed]="jobcodeClosed()"
      >
        <nb-accordion-item-header class="accordion-header">
          <div>
            <span>Jobcodes</span>
          </div>
          <div class="item-tags" *ngIf="jobcodeClosed()">
            @for (jobcode of jobcodesQuickInfo; track jobcode) {
              <nb-tag size="tiny" [text]="jobcode"> </nb-tag>
            }
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body class="m-0">
          <next-object-list
            object="WorkOrderSegmentLine"
            height="250"
            [hideControlBar]="false"
            (allRecords)="onJobcodeRecords($event)"
            [hideToolBar]="true"
            [hideQueues]="true"
            [filters]="[
              {
                column: 'workOrderSegment.id',
                value: segment?.id
              },
              {
                column: 'type',
                value: 'Jobcode'
              },
              {
                column: 'createdAt',
                value: '>=' + (segment?.createdAt | date: 'yyyy-MM-dd')
              }
            ]"
          ></next-object-list>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item
        (collapsedChange)="onPartsCollapseChanged($event)"
        [collapsed]="partsClosed()"
      >
        <nb-accordion-item-header class="accordion-header">
          <div>
            <span>Parts</span>
            <!--<indicator-dot></indicator-dot>-->
          </div>
          <div class="item-tags" *ngIf="partsClosed()">
            <button nbButton size="small" status="success">
              Total: {{ totalParts }}
            </button>
            <button
              nbButton
              size="small"
              status="warning"
              *ngIf="totalPartsNotPosted"
            >
              Unposted: {{ totalPartsNotPosted }}
            </button>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body class="m-0">
          <next-object-list
            object="WorkOrderSegmentLine"
            height="250"
            (allRecords)="onPartRecords($event)"
            [hideControlBar]="false"
            [hideToolBar]="true"
            [hideQueues]="true"
            [filters]="[
              {
                column: 'workOrderSegment.id',
                value: segment?.id
              },
              {
                column: 'type',
                value: 'Part'
              },
              {
                column: 'createdAt',
                value: '>=' + (segment?.createdAt | date: 'yyyy-MM-dd')
              }
            ]"
          ></next-object-list>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item
        (collapsedChange)="onHoursCollapseChanged($event)"
        [collapsed]="hoursClosed()"
      >
        <nb-accordion-item-header class="accordion-header"
          >Hours
          <div class="item-tags" *ngIf="hoursClosed()">
            <button nbButton size="small" status="success">
              Total:
              {{ badgeDetails.totalHours }}
            </button>
            <button
              nbButton
              size="small"
              status="warning"
              *ngIf="badgeDetails.unpostedHours"
            >
              Unposted: {{ badgeDetails?.unpostedHours }}
            </button>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <next-object-list
            object="TimeSheet"
            height="250"
            (allRecords)="onTimeSheetRecords($event)"
            [hideControlBar]="false"
            [hideToolBar]="true"
            [hideQueues]="true"
            [filters]="[
              {
                column: 'workOrderSegment.id',
                value: segment?.id
              }
            ]"
          ></next-object-list>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item
        (collapsedChange)="onChargesCollapseChanged($event)"
        [collapsed]="chargesClosed()"
      >
        <nb-accordion-item-header class="accordion-header"
          >Charges
          <div class="item-tags" *ngIf="chargesClosed()">
            <button
              nbButton
              size="small"
              status="warning"
              *ngIf="chargeLinesNotPosted"
            >
              Unposted: {{ chargeLinesNotPosted }}
            </button>
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body>
          <next-object-list
            object="WorkOrderSegmentLine"
            height="250"
            [hideControlBar]="false"
            [hideToolBar]="true"
            [hideQueues]="true"
            [filters]="[
              {
                column: 'workOrderSegment.id',
                value: segment?.id
              },
              {
                column: 'type',
                value: 'Charge,Purchase'
              }
            ]"
          ></next-object-list>
        </nb-accordion-item-body>
      </nb-accordion-item>
      <nb-accordion-item
        (collapsedChange)="onNotesCollapseChanged($event)"
        [collapsed]="notesClosed()"
      >
        <nb-accordion-item-header class="accordion-header"
          >Notes
          <div class="item-tags" *ngIf="notesClosed()">
            <div *ngFor="let tab of tabs">
              <button
                class="note-tag"
                *ngIf="segment[tab.key].reportingCodes.length > 0"
                nbButton
                size="small"
                status="success"
              >
                {{ tab.title }}
              </button>
            </div>
            <!--<div class="accordion-header-avatars">
                <img
                  *ngFor="let avatarUrl of avatarUrls"
                  class="note-accordion-header-avatar"
                  [src]="avatarUrl"
                />
              </div>-->
          </div>
        </nb-accordion-item-header>
        <nb-accordion-item-body class="notes-item">
          <div class="row">
            <div class="col-lg-12">
              <nb-tabset (changeTab)="onTabChange($event)">
                <nb-tab
                  class="notes-tab"
                  #tab
                  *ngFor="let tab of tabs"
                  [tabTitle]="tab.title"
                  [badgeText]="segment[tab.key].reportingCodes.length"
                  badgePosition="top right"
                  badgeStatus="primary"
                >
                  <div *ngIf="tab.title !== 'Internal'" class="codes-container">
                    <div class="codes-header">
                      <div><b>Reporting Codes</b></div>
                      <div
                        class="test"
                        style="display: flex; align-items: center"
                      >
                        <div #dropdownContainer class="dropdown-container">
                          <nb-form-field>
                            <nb-icon
                              nbPrefix
                              icon="search"
                              pack="eva"
                            ></nb-icon>
                            <input
                              autocomplete="off"
                              class="dropdown-input"
                              status="primary"
                              #autoInput
                              nbInput
                              fieldSize="small"
                              type="text"
                              (input)="onChange(autoInput.value)"
                              placeholder="Search Codes"
                              (focus)="onInputFocus(autoInput)"
                            />
                          </nb-form-field>
                          <div
                            [hidden]="!inputFocused"
                            #customDropdown
                            class="custom-dropdown"
                          >
                            <div
                              class="custom-dropdown-option"
                              *ngFor="
                                let option of filteredOptions;
                                trackBy: trackByFn
                              "
                              [value]="option.name"
                              (click)="toggleSelection(option)"
                            >
                              <div>
                                <input
                                  class="custom-dropdown-option-checkbox"
                                  type="checkbox"
                                  [checked]="isSelected(option)"
                                />
                              </div>
                              <div class="custom-dropdown-option-label">
                                <span
                                  class="custom-dropdown-option-label-name"
                                  >{{ option.name }}</span
                                >
                                <div
                                  class="custom-dropdown-option-label-description"
                                >
                                  <small>{{ option.description }}</small>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <nb-tag-list class="codes-tag-container">
                      @for (
                        code of segment[tab.key].reportingCodes;
                        track code.id
                      ) {
                        <nb-tag
                          appearance="filled"
                          status="primary"
                          nz-tooltip
                          nzTooltipTitle="{{ code.reportingCode.description }}"
                          [removable]="codeEditMode"
                          (remove)="open(dialog, code)"
                          [text]="code.reportingCode.name"
                        >
                        </nb-tag>
                      }
                    </nb-tag-list>
                  </div>
                  <ng-template #dialog let-data let-ref="dialogRef">
                    <nb-card accent="warning">
                      <nb-card-header
                        class="dialog-header"
                        style="display: flex; justify-content: center"
                        >Are you sure?
                      </nb-card-header>
                      <nb-card-body>
                        <div style="display: flex; justify-content: center">
                          <nb-icon
                            style="font-size: 4rem; color: #ffaa00"
                            class="delete-icon"
                            icon="alert-circle-outline"
                          ></nb-icon>
                        </div>
                        <p>
                          Delete reporting code
                          {{ data.reportingCode.name }} ?
                        </p>
                      </nb-card-body>
                      <nb-card-footer class="codes-dialog">
                        <button nbButton (click)="ref.close()">Cancel</button>
                        <button
                          nbButton
                          status="primary"
                          (click)="onTagRemove(data, ref)"
                        >
                          Yes
                        </button>
                      </nb-card-footer>
                    </nb-card>
                  </ng-template>
                  <div class="codes-header">
                    <div>
                      <b><br />Notes</b>
                    </div>
                    <div class="codes-header-toggle"></div>
                  </div>
                  <div class="note-add-new">
                    <div class="note-header">
                      <img class="note-header-avatar" [src]="user.avatarURL" />
                      <div class="note-header-name">
                        {{ user.name }}
                      </div>
                    </div>
                    <div class="note-edit-area">
                      <textarea
                        [ngClass]="
                          isAddingNewNote
                            ? 'expanded-textarea'
                            : 'collapsed-textarea'
                        "
                        nbInput
                        fullWidth
                        rows="3"
                        [(ngModel)]="newNote"
                        placeholder="Enter notes"
                        (focus)="addNewNote()"
                      >
                      </textarea>
                      <div *ngIf="isAddingNewNote" class="note-add-new-actions">
                        <button
                          nbButton
                          status="primary"
                          (click)="saveNewNote(tab.title, tab.key)"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                  <div
                    *ngFor="
                      let note of segment[tab.key].notes;
                      let i = index;
                      let last = last
                    "
                  >
                    <div class="note" [ngClass]="{ 'last-note': last }">
                      <div class="note-header">
                        <img
                          class="note-header-avatar"
                          [src]="note.createdByRef?.avatarURL"
                        />
                        <div class="note-header-name">
                          {{ note.createdByRef?.firstname }}
                          {{ note.createdByRef?.lastname }}
                        </div>
                        <div
                          class="note-header-editor"
                          [style.visibility]="
                            note.updatedBy !== note.createdBy
                              ? 'visible'
                              : 'hidden'
                          "
                        >
                          <span
                            nz-tooltip
                            nzTooltipTitle="{{
                              note.updatedByRef?.firstname
                            }} {{ note.updatedByRef?.lastname }}"
                          >
                            Edited
                          </span>
                        </div>
                        <div
                          class="note-header-editor-bullet"
                          [style.visibility]="
                            note.updatedBy !== note.createdBy
                              ? 'visible'
                              : 'hidden'
                          "
                        >
                          <span>&bull;</span>
                        </div>
                        <div class="note-header-date">
                          {{ note.updatedAt | date: 'short' }}
                        </div>
                        <button
                          nbButton
                          ghost
                          status="basic"
                          [nbContextMenu]="noteMenuItems"
                          [nbContextMenuTag]="{ id: note.id, key: tab.key }"
                          nbContextMenuTrigger="click"
                        >
                          <nb-icon icon="more-horizontal-outline"></nb-icon>
                        </button>
                      </div>
                      <div
                        class="note-edit-area"
                        *ngIf="i === noteEditIndex; else noteContent"
                      >
                        <textarea
                          nbInput
                          fullWidth
                          [(ngModel)]="editedNoteControl"
                          rows="4"
                        >
                        </textarea>
                        <div class="note-add-new-actions">
                          <button
                            nbButton
                            status="primary"
                            (click)="saveEditNote(tab.title, tab.key)"
                          >
                            Save
                          </button>
                          <button
                            nbButton
                            status="basic"
                            (click)="cancelEditNote()"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                      <ng-template #noteContent>
                        <div class="note-body">{{ note.note }}</div>
                      </ng-template>
                    </div>
                  </div>
                </nb-tab>
              </nb-tabset>
            </div>
          </div>
        </nb-accordion-item-body>
      </nb-accordion-item>
    </nb-accordion>
  </div>
  <!-- </nb-card-body>
</nb-card> -->
</div>
