<div [ngSwitch]="button.type">
  <button
    *ngSwitchDefault
    nbButton
    ghost
    size="small"
    status="medium"
    [disabled]="button.isDisabled || disabled"
    [nbSpinner]="button.loading"
    [nbSpinnerStatus]="button.loading ? 'primary' : 'primary'"
    [nzPopoverAction]="'click'"
    [nzPopoverContent]="button.popOver"
    (click)="emitButtonAction(button, $event)"
  >
    <i
      class="fa-solid {{ button.icon }} mr-2"
      [style.color]="button.iconColor"
    ></i>
    {{ button.label }}
  </button>

  <div *ngSwitchCase="'divider'">
    <div class="menu-spacer">&nbsp;</div>
  </div>

  <div *ngSwitchCase="'dropdown'">
    <button
      nbButton
      size="small"
      status="medium"
      [disabled]="button.isDisabled"
      nz-dropdown
      [nzDropdownMenu]="menu"
      [nbPopover]="button.action == 'popover' ? popoverComponent : null"
      nbPopoverPlacement="bottom"
      ghost
    >
      <i class="fa-light fa-files mr-2"></i>
      {{ button.label }}
      <i class="fa-light fa-caret-down ml-2"></i>
    </button>

    <nz-dropdown-menu #menu="nzDropdownMenu">
      <ul nz-menu nzSelectable>
        <li nz-menu-item *ngFor="let childButton of button?.children">
          <grid-menu-button [button]="childButton"></grid-menu-button>
        </li>
        <!-- <li nz-menu-item>
          <button ghost nbButton status="primary" size="small">
            <i class="fa-light fa-file-invoice mr-2"></i>
            Purchase Orders
          </button>
        </li>
        <li nz-menu-item>
          <button ghost nbButton status="primary" size="small">
            <i class="fa-light fa-screwdriver-wrench mr-2"></i>
            Service Calls
          </button>
        </li>
        <li nz-menu-item nzDanger>
          <button ghost nbButton status="primary" size="small">
            <i class="fa-light fa-file-contract mr-2"></i>
            Contracts
          </button>
        </li> -->
      </ul>
    </nz-dropdown-menu>
  </div>
</div>

<ng-template #template> test123 </ng-template>
