import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
  ViewContainerRef,
} from '@angular/core';
import { debounceTime, distinctUntilChanged, map, tap } from 'rxjs';
import { AttachmentService } from '../../services/attachment.service';
import { NbDialogService, NbPopoverDirective } from '@nebular/theme';
import { DtoAddAttachment } from '../../../../@core/interfaces/DtoAddAttachment';
import { SubSink } from 'subsink';
import { NgxMasonryComponent } from 'ngx-masonry';
import { fromEvent } from 'rxjs';
import { AttachmentImageComponent } from '../attachment-image/attachment-image.component';
const SmartPhoto = require('smartphoto');
import { HttpClient } from '@angular/common/http';

export interface associatedAttachmentObject {
  refId: string;
  object: string;
  label?: string;
}
@Component({
  selector: 'next-attachment',
  templateUrl: './attachment.component.html',
  styleUrls: ['./attachment.component.scss'],
})
export class AttachmentComponent implements OnInit, OnDestroy {
  @ViewChild('mas') mas: NgxMasonryComponent;
  _refId: string;
  _associatedObjects: associatedAttachmentObject[] = [];
  @Input() set associatedObjects(
    associatedObjects: associatedAttachmentObject[],
  ) {
    this._associatedObjects = associatedObjects;
    this.getAssociatedAttachments();
  }
  get associatedObject() {
    return this._associatedObjects;
  }

  @Input() set refId(refId: string) {
    this._refId = refId;
    this.getAttachments();
  }
  get refId() {
    return this._refId;
  }

  @Input() object: string;
  @Input() subject: string;
  @Input() showToolbar = true;
  @Input() showAsButton = false;
  @Input() buttonLabel: string;
  @Input() attachmentType: string;
  _requiredFileTypes: string[];
  @Input() set requiredFileTypes(requiredFileTypes: string[]) {
    this._requiredFileTypes = requiredFileTypes;
    this.checkHasAllRequiredFiles();
  }
  get requiredFileTypes() {
    return this._requiredFileTypes;
  }

  @Output() onImageLoaded: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() attachmentCount: EventEmitter<number> = new EventEmitter<number>();
  @Output() attachments: EventEmitter<number> = new EventEmitter<number>();
  @Output() hasRequiredFiles: EventEmitter<boolean> =
    new EventEmitter<boolean>();
  @Output() fileUploaded: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('zoomDialog', { static: true }) zoomDialog: TemplateRef<any>;
  @ViewChild('searchInput', { static: true }) searchInput: ElementRef;
  @ViewChild('masAttachment', { static: true }) _attachments;
  @ViewChild('parent', { static: true }) parent: ElementRef;
  masAttachment: AttachmentImageComponent;
  apiResponse: any;
  sub = new SubSink();
  filteredAttachments;
  uploadFiles;
  isUploading = false;
  searchTxt = '';
  isLoading = false;
  showOnlyTruckFiles: boolean = false;
  showAsList: boolean = false;
  showAsGallery: boolean = true;
  imageWidth: number = 280;
  imageSideAdjustment: number = 0;
  gridOptions;
  innerWidth;
  associatedAttachmentsMap = new Map();
  showAssociatedAttachments = true;
  associatedAttachmentsObs = [];
  attachmentsObs;
  zoomedAttachment;

  documentTypes = [
    'Equipment',
    'Equipment Damage',
    'Signed Document',
    'Purchase Order',
    'Invoice',
    'Misc',
  ];
  columnDefs = [];

  constructor(
    private attachmentService: AttachmentService,
    private dialogService: NbDialogService,
    private el: ViewContainerRef,
    private httpClient: HttpClient,
  ) {}

  async ngOnInit() {
    this.innerWidth = window.innerWidth;

    this.setupGridOptions();
    this.setupAttachmentLoadSub();
    this.setupSearchSub();
    // await this.getAttachments();
  }

  ngOnDestroy(): void {
    this.sub.unsubscribe();
  }

  async refreshAll() {
    await this.getAttachments();
    await this.getAssociatedAttachments();
  }

  setupAttachmentLoadSub() {}

  setupSearchSub() {
    if (!this.showToolbar || !this.searchInput) {
      return;
    }

    this.sub.sink = fromEvent(this.searchInput.nativeElement, 'keyup')
      .pipe(
        // get value
        map((event: any) => {
          return event.target.value;
        }),
        debounceTime(500),

        distinctUntilChanged(),
      )
      .subscribe((filter) => {
        this.searchTxt = filter;
        this.filter(this.searchTxt);
      });
  }

  showFileSelect() {
    //what if i want to use the id of the input to trigger the click event
    document.getElementById('fileInput').click();
  }

  async handleSingleUpload(event) {
    const files = event.target.files;
    const file = files[0];
    const cleanedFileName = this.cleanFileName(file.name);
    const dto: DtoAddAttachment = {
      refId: this.refId,
      object: this.object,
      fileName: cleanedFileName,
      isTruckFile: false,
      type: this.attachmentType || 'Misc',
      description: '',
    };

    try {
      const attachment = await this.attachmentService.addAttachment(dto, file);
      this.fileUploaded.emit({ attachment, file: file });
    } catch (e) {
      alert('Error uploading file');
    }
  }

  closeDeletePopup() {
    // console.log(this.dialogDeleteAttachment);
    // this.dialogDeleteAttachment.elementRef.nativeElement.close();
  }

  async email(attachment) {
    if (!attachment?.signedURL) {
      return;
    }

    const base64 = await this.attachmentService.getBase64ImageFromUrl(
      attachment?.signedURL,
    );

    let type = 'jpg';
    if (attachment?.fileName?.indexOf('pdf') > 0) type = 'pdf';
    const base64data = base64.split(',')[1];
  }

  async getAttachments(reloadItems = false) {
    if (this.attachmentsObs) {
      this.attachmentsObs.unsubscribe();
    }
    this._attachments = [];
    this.isLoading = true;
    this.sub.sink = this.attachmentsObs = this.attachmentService
      .getAttachments(this.refId, this.object)
      .pipe(
        tap(({ data }) => {
          this._attachments = (<any>data)?.getAttachments;
          this._attachments = this._attachments.map((attachment) => {
            return {
              ...attachment,
              retryCount: 0,
              imageError: false,
            };
          });
          this.filter(this.searchTxt);
          this.isLoading = false;
          this.checkHasAllRequiredFiles();
          this.attachments.emit(this._attachments);
        }),
      )
      .subscribe();
  }

  getAssociatedAttachments() {
    if (!this._associatedObjects?.length) return;
    this.associatedAttachmentsMap.clear();
    if (this.associatedAttachmentsObs.length) {
      this.associatedAttachmentsObs.forEach((obs) => obs.unsubscribe());
      this.associatedAttachmentsObs = [];
    }

    this._associatedObjects.forEach(async (associatedObject) => {
      const obs = this.attachmentService
        .getAttachments(associatedObject.refId, associatedObject.object)
        .pipe(
          tap(({ data }) => {
            const attachment: any = (<any>data)?.getAttachments.map(
              (attachment) => {
                return {
                  ...attachment,
                  associatedObject: associatedObject.label,
                  retryCount: 0,
                  imageError: false,
                };
              },
            );
            this.associatedAttachmentsMap.set(
              associatedObject.refId,
              attachment,
            );
            this.filter(this.searchTxt);
          }),
        )
        .subscribe();
      this.sub.sink = obs;
      this.associatedAttachmentsObs.push(obs);
    });
  }

  checkHasAllRequiredFiles() {
    const result = this.requiredFileTypesLeft?.length === 0;
    this.hasRequiredFiles.emit(result);
  }

  reloadMas() {
    if (!this.mas) {
      return;
    }
    window.dispatchEvent(new Event('resize'));
    this.mas.reloadItems();
    this.mas.layout();
  }

  imageDidLoad() {
    this.onImageLoaded.emit(true);
  }

  public dropped(files: any[], templateRef = null) {
    this.uploadFiles = files.map((file) => {
      file.fileType = file.fileEntry.name.split('.').pop();
      return file;
    });
    if (templateRef) {
      this.dialogService.open(templateRef);
    }
  }

  openDialog(dialog: TemplateRef<any>, context = {}) {
    return this.dialogService.open(dialog, {
      viewContainerRef: this.parent.nativeElement,
      context,
    });
  }

  removeFile(index) {
    this.uploadFiles.splice(index, 1);
  }

  toggleAll(event) {
    this.uploadFiles.forEach((file) => {
      file.truckFile = event;
    });
  }

  toggleAllAttachmentType(event) {
    this.uploadFiles.forEach((file) => {
      file.attachmentType = event;
    });
  }

  private cleanFileName(fileName: string): string {
    return fileName
      .replace(/[^a-zA-Z0-9.-]/g, '_') // Replace unsupported characters with underscores
      .replace(/\s+/g, '_'); // Replace spaces with underscores
  }

  async submitUpload(dialog) {
    this.isUploading = true;

    await Promise.all(
      this.uploadFiles.map(async (droppedFile) => {
        if (droppedFile.fileEntry.isFile) {
          const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
          await fileEntry.file(async (file: File) => {
            if (
              !droppedFile.relativePath.endsWith(`.${droppedFile.fileType}`)
            ) {
              droppedFile.relativePath += `.${droppedFile.fileType}`;
            }
            const cleanedFileName = this.cleanFileName(
              droppedFile.relativePath,
            );
            const dto: DtoAddAttachment = {
              refId: this.refId,
              object: this.object,
              fileName: cleanedFileName,
              isTruckFile: droppedFile.truckFile,
              type: droppedFile.attachmentType,
              description: droppedFile.notes,
            };
            try {
              droppedFile.status = 'Uploading';
              await this.attachmentService.addAttachment(dto, file);
              droppedFile.status = 'Uploaded';
            } catch {
              this.isUploading = false;
              throw new Error('Error uploading file');
            }
          });
        } else {
          const fileEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        }
      }),
    );
    this.getAttachments();
    this.isUploading = false;
    dialog.close();
    this.checkHasAllRequiredFiles();
  }

  filter(search) {
    const associatedAttachments = Array.from(
      this.associatedAttachmentsMap.values(),
    ).flat();

    const combinedAttachments = this._attachments.concat(
      this.showAssociatedAttachments ? associatedAttachments : [],
    );

    if (!combinedAttachments?.length) {
      this.filteredAttachments = [];
      return;
    }
    let filter = combinedAttachments.filter((attachment) => {
      return (
        attachment?.fileName?.toLowerCase().indexOf(search?.toLowerCase()) >
          -1 ||
        attachment?.type?.toLowerCase().indexOf(search?.toLowerCase()) > -1 ||
        attachment?.createdBy?.fullName
          ?.toLowerCase()
          .indexOf(search?.toLowerCase()) > -1
      );
    });

    if (this.showOnlyTruckFiles) {
      filter = filter.filter((attachment) => {
        return attachment?.isTruckFile === true;
      });
    }

    this.filteredAttachments = filter.sort((a, b) => {
      return (
        new Date(b?.createdAt).getTime() - new Date(a?.createdAt).getTime()
      );
    });

    this.mas?.layout();
  }

  toggletruckFileOnly(event) {
    this.showOnlyTruckFiles = event;
    this.filter(this.searchTxt);
  }

  toggleAssociatedAttachments(event) {
    this.showAssociatedAttachments = event;
    this.filter(this.searchTxt);
  }

  adjustImageSize(size) {
    this.imageSideAdjustment += size;

    if (this.imageSideAdjustment < 0) this.imageSideAdjustment = 0;
    if (this.imageSideAdjustment > 3) this.imageSideAdjustment = 3;

    switch (this.imageSideAdjustment) {
      case 0:
        this.imageWidth = 280;
        break;
      case 1:
        this.imageWidth = 600;
        break;
      case 2:
        this.imageWidth = window.innerWidth - 500;
        break;
    }
    window.dispatchEvent(new Event('resize'));
    this.mas.reloadItems();
    this.mas.layout();
  }

  showAttachmentZoom(id) {
    const attachment = this._attachments.find(
      (attachment) => attachment.id === id,
    );
    this.zoomedAttachment = this.openDialog(this.zoomDialog, attachment);
  }

  closeAttachmentZoom() {
    this.zoomedAttachment.close();
  }

  setupGridOptions() {
    this.setupColumnDefs();
    if (this.gridOptions) return;
    const _this = this;
    this.gridOptions = {
      onRowDoubleClicked: (row) => {
        this.openDialog(this.zoomDialog, row.data);
      },
      columnDefs: this.columnDefs,
      rowSelection: 'single',
      toolbar: true,
      enableSorting: true,
      filter: true,
      defaultColDef: {
        resizable: true,
        sortable: true,
        filter: 'agTextColumnFilter',
        floatingFilter: true,
        suppressMenu: true,
        suppressFilterButton: true,
        filterParams: { buttons: ['reset'], debounceMs: 800 },
        floatingFilterComponentParams: {
          suppressFilterButton: true,
          debounceMs: 200,
        },
      },
    };
  }

  setupColumnDefs() {
    this.columnDefs = [
      {
        headerName: 'Name',
        field: 'fileName',
        width: 400,
      },
      {
        headerName: 'Type',
        field: 'type',
        width: 130,
      },
      {
        headerName: 'Truck File',
        field: 'isTruckFile',
        width: 95,
        hide: this.object === 'Equipment' ? false : true,
        cellRenderer: (params) => {
          return params.value
            ? '<span class="badge badge-primary">Truck File</span>'
            : '';
        },
      },
      {
        headerName: 'Notes',
        field: 'description',
        width: 350,
      },
      {
        headerName: 'Created By',
        field: 'createdBy.fullName',
        width: 130,
      },

      {
        headerName: 'Created At',
        field: 'createdAt',
        width: 170,
        valueFormatter: (data) => {
          return data.value ? new Date(data.value).toLocaleString() : '';
        },
      },
    ];
  }

  get requiredFileTypesLeft() {
    return this.requiredFileTypes?.filter(
      (x) =>
        !this._attachments?.find(
          (y) => y.type.toLowerCase() === x.toLowerCase(),
        ),
    );
  }

  async removeAttachment(attachment, dialog) {
    this.sub.sink = this.attachmentService
      .removeAttachment(attachment.id)
      .subscribe();
    dialog.close();
  }

  closeModal(dialog) {
    dialog.close();
  }
}
