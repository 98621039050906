import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { HttpClient } from '@angular/common/http';
import { QueueApiService } from './queue-api.service';
import { DtoAddQueue } from '../dto/dtoAddQueue.dto';
import { map } from 'rxjs/operators';
import { DtoGetQueuesByObject } from '../dto/DtoGetQueuesByObject';
@Injectable({
  providedIn: 'root',
})
export class QueueService {
  constructor(private api: QueueApiService) {}

  addQueue(dto: DtoAddQueue) {
    return this.api.addQueue(dto);
  }

  deleteQueue(id: string) {
    return this.api.deleteQueue(id);
  }

  getQueuesByObjectType(dto: DtoGetQueuesByObject) {
    const query = this.api.getQueuesByObjectType(dto);

    const sub = query.valueChanges.pipe(
      map(({ data }) => {
        const rtn = data['getQueuesByObjectType'];
        return rtn.map((x, i) => {
          const y = { ...x };
          y['recordCount'] = -1;
          y['lastUpdated'] = new Date();
          y['pressed'] = 0;
          return y;
        });
      })
    );
    return { query, sub };
  }

  queuesAreSame(a, b) {
    if (a.length !== b.length) {
      return false;
    }
    for (let i = 0; i < a.length; i++) {
      if (a[i].column !== b[i].column) {
        return false;
      }

      if (a[i].value !== b[i].value) {
        return false;
      }
    }
    return true;
  }
}
