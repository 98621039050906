<div class="bar">
  <div class="d-flex btn-group btn-group-toggle" data-toggle="buttons">
    <nb-button-group
      size="small"
      cdkDropList
      cdkDropListOrientation="horizontal"
      (cdkDropListDropped)="onDrop($event)"
    >
      <button nbButtonToggle (click)="tabClick(null)">
        <nb-icon icon="menu-outline"></nb-icon>
      </button>
      <button
        nbButtonToggle
        cdkDrag
        outline
        [(pressed)]="tab.pressed"
        (click)="tabClick(tab)"
        *ngFor="let tab of filters; let i = index"
      >
        {{ tab.name }}

        <span
          class="badge filterTabsCount"
          style="margin-left: 1px"
          [class]="tab.badgeColor ? 'badge-' + tab.badgeColor : 'badge-light'"
        >
          <i
            class="b-fa b-fa-spinner b-fa-pulse b-fa-spin"
            *ngIf="tab.recordCount < 0"
          ></i>
          {{ tab.recordCount < 0 ? '' : tab.recordCount }}
        </span>
      </button>
    </nb-button-group>
    <span class="flex-expand"></span>

    <button readonly nbButton ghost size="tiny" status="basic" class="mb-1">
      {{ adhocFilter?.recordCount < 0 ? '' : adhocFilter?.recordCount || 0 }}
      records
    </button>
    <button
      nbButton
      ghost
      size="tiny"
      status="primary"
      class="mb-1"
      [nbPopover]="saveFilterRef"
      [nbPopoverPlacement]="'left'"
      *ngIf="adhocFilter?.filter?.length > 0 && !hideQueues"
    >
      <nb-icon class="mr-1" icon="save-outline"></nb-icon>
      Save Filter
      <span class="badge filterTabsCount badge-light">
        <i
          class="b-fa b-fa-spinner b-fa-pulse b-fa-spin"
          *ngIf="adhocFilter?.recordCount === null"
        ></i>
      </span>
    </button>
    <button
      nbButton
      ghost
      size="tiny"
      class="mb-1"
      status="primary"
      (click)="deleteQueue(isPressed.id)"
      *ngIf="isPressed?.type === 'Private' && !adhocFilter?.filter"
    >
      Remove Filter
    </button>

    <button
      class="settings"
      nbButton
      ghost
      size="medium"
      (click)="showSettings()"
    >
      <nb-icon icon="settings-outline"></nb-icon>
    </button>
    <button class="settings" nbButton ghost size="medium" (click)="refresh()">
      <nb-icon icon="refresh-outline"></nb-icon>
    </button>
  </div>
</div>

<ng-template #saveFilterRef>
  <nb-card class="margin-bottom-0" style="min-width: 250px" accent="info">
    <nb-card-header>Save Filter</nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="customerEquipmentNum" class="label required">
              Filter Name</label
            >
            <div>
              <input
                nbInput
                class="form-control"
                type="text"
                placeholder="Filter Name"
                [(ngModel)]="filterName"
              />
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button
        (click)="saveFilter()"
        nbButton
        fullWidth
        hero
        ghost
        status="primary"
      >
        Save Filter
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>

<ng-template #editFilterRef>
  <nb-card class="margin-bottom-0" style="min-width: 250px" accent="info">
    <nb-card-header>Edit Filter</nb-card-header>
    <nb-card-body>
      <div class="row">
        <div class="col">
          <div class="form-group">
            <label for="customerEquipmentNum" class="label required">
              Filter Name</label
            >
            <div>
              <input
                nbInput
                class="form-control"
                type="text"
                placeholder="Filter Name"
                [(ngModel)]="filterName"
              />
            </div>
          </div>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <button
        (click)="saveFilter()"
        nbButton
        fullWidth
        hero
        ghost
        status="primary"
      >
        Save Filter
      </button>
    </nb-card-footer>
  </nb-card>
</ng-template>
