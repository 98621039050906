import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { DynamicListViewModule } from '../../../../shared/component/dynamiclistview/dynamiclistview.module';

@Component({
  standalone: true,
  imports: [CommonModule, DynamicListViewModule],
  selector: 'next-checklist-detail',
  templateUrl: './checklist-detail.component.html',
  styleUrls: ['./checklist-detail.component.scss'],
})
export class ChecklistDetailComponent {
  constructor() {}
  @Input() workOrderId;

  selectChecklist() {
    return false;
  }
}