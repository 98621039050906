import { gql } from 'apollo-angular';

export const Query_GetWorkersByTeamBranch = gql`
  query ($branch: String!, $teams: [String!]!) {
    getWorkersByTeamBranch(branch: $branch, teams: $teams) {
      id
      displayName
      workerNum
      location
      team
      homeLat
      homeLng
      user {
        id
        email
        avatarURL
      }
    }
  }
`;
