import { moveItemInArray } from '@angular/cdk/drag-drop';
import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';

import { _finally } from 'rxjs-compat/operator/finally';
import { SubSink } from 'subsink';

const jsonLogic = require('json-logic-js');
@Component({
  selector: 'grid-queue',
  templateUrl: './grid-queue.component.html',
  styleUrls: ['./grid-queue.component.scss'],
})
export class GridQueueComponent implements OnInit, AfterViewChecked {
  sub = new SubSink();
  @Output() onClick = new EventEmitter();
  @Output() onAddQueue = new EventEmitter();
  @Output() onDeleteQueue = new EventEmitter();
  @Output() onShowSettings = new EventEmitter();
  @Output() onRefresh = new EventEmitter();
  @Input() queueObs: any;
  @Input() adhocFilter: any;
  @Input() object: string;
  @Input() hideQueues: boolean = false;
  //@Input() filters: any = [];

  _filters: any = [];
  get filters() {
    if (this.hideQueues) return [];
    return this._filters;
  }
  @Input() set filters(filters: any) {
    this._filters = filters;
    setTimeout(() => {
      this.runBadgeLogic();
    }, 1000);
  }

  filterName: string = '';
  filterType: string = 'Private';
  selectedTab;

  constructor() {}

  ngAfterViewChecked(): void {
    //this.changeDetectorRef.detectChanges();
  }

  deleteQueue(id) {
    this.onDeleteQueue.emit(id);
  }

  ngOnInit(): void {}

  showSettings() {
    this.onShowSettings.emit(true);
  }

  runBadgeLogic() {
    this.filters?.forEach((x) => {
      if (x.badgeLogic) {
        x.badgeColor = jsonLogic.apply(x.badgeLogic, {
          recordCount: x.recordCount,
        });
      }
    });
  }

  tabClick(tab) {
    this.selectedTab = tab;
    this.onClick.emit(tab?.filter);
  }

  saveFilter() {
    const dto: any = {
      name: this.filterName,
      type: this.filterType,
      filter: this.adhocFilter.filter,
    };
    this.onAddQueue.emit(dto);
    this.adhocFilter = null;
    this.filterName = '';
  }

  get isPressed() {
    return this.filters.filter((x) => x.pressed)[0];
  }

  onDragover(filter) {
    console.log(filter);
  }

  onDragStart(filter) {
    console.log(filter);
  }

  onDrop(filter) {
    const { currentIndex, previousIndex } = filter;
    moveItemInArray(this.filters, previousIndex, currentIndex);
    this.saveSortToLocalStorage();
  }

  saveSortToLocalStorage() {
    let sort = this.filters.map((x) => x.id);
    localStorage.setItem(`queueSort-${this.object}`, JSON.stringify(sort));
  }
  refresh() {
    this.onRefresh.emit(true);
  }
}
