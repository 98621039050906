import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {
  NbAutocompleteComponent,
  NbAutocompleteDirective,
  NbOptionComponent,
  NbPopoverDirective,
} from '@nebular/theme';
import { CustomerService } from '../../services/customer.service';
import { ContactListComponent } from '../contact-list/contact-list.component';

@Component({
  selector: 'contact-input',
  templateUrl: './contact-input.component.html',
  styleUrls: ['./contact-input.component.scss'],
})
export class ContactInputComponent implements OnInit {
  _value: any;
  name: string = '';
  label: string = '';
  lookupPopover = ContactListComponent;
  autocompleteDirective: NbAutocompleteDirective<string>;

  @ViewChild('list', { read: TemplateRef }) templateList: TemplateRef<any>;
  @ViewChild(NbPopoverDirective) popOverRef: NbPopoverDirective;
  @ViewChild('autooption') autooption: NbOptionComponent;
  @Output()
  onValueChange = new EventEmitter();
  @Input() filters: any[];
  @Input() locked: boolean = false;
  @Input() hideCustomerNum: boolean;
  @Input() hideCustomerName: boolean;
  @Input() set value(val: any) {
    this._value = val;
    this.updateLabel();
  }

  get value() {
    return this._value;
  }

  constructor(private readonly customerService: CustomerService) {}

  ngOnInit(): void {}

  updateLabel() {
    if (!this.value) {
      this.label = '';
      return;
    }
    this.label = `${this.value?.firstname} ${this.value?.lastname}`;
    //if trim label is empty then set to contactNum.
    if (this.label.trim() == '') {
      this.label = this.value?.contactNum;
    }
    //this.label = this.value?.contactNum;
  }

  selected(customer) {
    this.value = customer;
    this.updateLabel();
    this.onValueChange.emit(customer);
    this.popOverRef.hide();
  }

  async newContact() {
    const customerIdFilter = this.filters.find(
      (f) => f.column === 'customerId',
    );
    const newContact = await this.customerService.showAddContact(
      customerIdFilter.value,
    );
    this.selected(newContact);
  }
}
