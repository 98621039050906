<next-object-list
  id="checklistList"
  object="Checklist"
  [selectFirstRow]="true"
  (onSelect)="selectChecklist()"
  [hideToolBar]="true"
  [filters]="[
    {
      column: 'workOrderId',
      value: this.workOrderId,
    }
  ]"
  height="200"
></next-object-list>