import { gql } from 'apollo-angular';

export const Subscription_WorkOrderUpdates = gql`
  subscription ($teams: [String!]!, $location: String!) {
    workOrderUpdates(teams: $teams, location: $location) {
      workOrderChangeLog {
        action
        id
        createdAt
        workOrder {
          id
          workOrderNum
        }
        user {
          fullName
          id
          worker {
            id
          }
        }
        newValue
        oldValue
      }
      workOrder {
        id
        type
        status
        workOrderNum
        serial
        make
        model
        address
        address1
        address2
        city
        state
        zipcode
        country
        lat
        lng
        subject
        scheduledStartDateTime
        scheduledEndDateTime
        isPastDue
        isMissed
        isClosed
        rescheduleCount
        pastDueCount
        missedCount
        worker {
          id
          displayName
        }
        shipTo {
          id
          name
          customerNum
        }
        equipment {
          id
          equipmentNum
          make
          model
          lastMeterReading
          lastMeterReadingDate
        }
      }
    }
  }
`;
