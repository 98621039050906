import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
} from '@angular/router';
import { of } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { TokenService } from '../services/token.service';
import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private router: Router, private tokenService: TokenService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return this.tokenService.get().pipe(
      map((token) => {
        if (!token) {
          this.router.navigate(['/auth/login'], {
            queryParams: { redirect: state.url },
            replaceUrl: true,
          });
          return false;
        }
        const requiredPermissions = route.data['permissions'] as string[];
        const userPermissions = (token && (token as any).permissions) || [];
        if (userPermissions.includes('SUPER_ADMIN')) {
          console.log('is super admin', );
          return true;
        }
        if (requiredPermissions && !requiredPermissions.some(permission => userPermissions.includes(permission))) {
          this.router.navigate(['/pages/unauthorized']);
          return false;
        }

        return true;
      }),
      catchError(() => {
        this.router.navigate(['/pages/unauthorized']);
        return of(false);
      })
    );
  }
}
