import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'ngx-workorder',
  templateUrl: './workorder.component.html',
  styleUrls: ['./workorder.component.scss'],
})
export class WorkOrderComponent implements OnInit {
  constructor(private router: Router) {}

  ngOnInit(): void {}

  onClick(workOrder) {
    console.log(workOrder);
    const { id } = workOrder;
    if (!id) return;
    this.router.navigate(['/pages/workorders/', id], {
      queryParams: { tab: 'Details' },
      queryParamsHandling: 'merge',
    });
  }
}
